<!-- add seasonal -->
<template>
  <v-container fluid class="content-wrapper">
    <!-- <h3 class="title-md">{{ $t("SigninPolicy.title") }}</h3> -->
    <div class="d-flex flex-row justify-start align-center mb-5">
      <h4 class="fw-600 mb-0">{{ $t("SigninSeasonal.btn_add") }}</h4>
      <v-btn
        icon
        class="ml-3"
        @click="GetUsedSeason()"
        :class="loadingData && 'animate__spining'"
        :disabled="loadingData"
      >
        <v-icon>mdi-reload</v-icon>
      </v-btn>
    </div>

    <!-- loading data -->
    <v-form v-if="loadingData">
      <v-skeleton-loader type="text@2"></v-skeleton-loader>
      <v-divider />
      <v-skeleton-loader type="text@2"></v-skeleton-loader>
      <v-divider />
    </v-form>

    <!-- show data -->
    <v-form v-else v-model="validation">
      <v-row row wrap>
        <v-col cols="12">
          <h3 class="title-sm-2">
            <v-icon left>mdi-tag-outline</v-icon>
            {{ $t("SigninSeasonal.seasonal_name") }}
          </h3>
          <v-text-field
            :rules="rules.name"
            v-model="season_name"
            placeholder="Season"
            class="mt-2 inputtext"
            color="#1e9ff2"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <h3 class="title-sm-2">{{ $t("SigninSeasonal.specify_seasonal") }}</h3>
        </v-col>
        <v-col cols="12">
          <template v-for="(item, index) in date_input">
            <v-row row wrap :key="index">
              <v-col cols="5">
                <v-menu
                  ref="item.start_menu"
                  v-model="item.start_menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :rules="rules.date"
                      v-model="item.start_format"
                      placeholder="DD/MM/YYYY"
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      class="inputtext"
                      color="#1e9ff2"
                      outlined
                      dense
                      readonly
                      @click:append="item.start_menu = true"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    no-title
                    color="#1e9ff2"
                    v-model="item.start"
                    :allowed-dates="allowedDates"
                    :min="item.min_startdate"
                    :show-current="item.min_startdate"
                    @input="formatStart(item.start, index)"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="5">
                <v-menu
                  ref="item.end_menu"
                  v-model="item.end_menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :rules="rules.date"
                      v-model="item.end_format"
                      placeholder="DD/MM/YYYY"
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      class="inputtext"
                      color="#1e9ff2"
                      outlined
                      dense
                      readonly
                      @click:append="item.end_menu = true"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    no-title
                    color="#1e9ff2"
                    v-model="item.end"
                    :min="item.min_enddate"
                    :allowed-dates="allowedDates"
                    @input="formatEnd(item.end, index)"
                    @change="checkSameday(item.start, item.end, index)"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="2">
                <!-- <v-icon v-if="index > 0" class="mt-2 mr-2" @click="RemoveRangedate(index)"
                  >mdi-minus-circle-outline</v-icon
                >
                <v-icon v-if="item.start && item.end" class="mt-2" @click="AddRangedate"
                  >mdi-plus-circle-outline</v-icon
                > -->
              </v-col>
            </v-row>
          </template>
        </v-col>
        <v-col cols="12">
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12">
          <v-btn depressed dark color="#ff9149" class="mr-3" @click="ConfirmBack">{{
            $t("SigninSeasonal.btn_back")
          }}</v-btn>
          <v-btn
            depressed
            color="#666ee8"
            :class="validation === true ? 'white--text' : ''"
            :disabled="!validation"
            @click="dialogConfirm = true"
          >
            <span>{{ $t("SigninGeneral.btn_save") }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-dialog v-model="dialogConfirm" persistent max-width="400">
      <v-card>
        <v-card-title
          class="text-center"
          style="font-size: 1rem !important; letter-spacing: normal !important"
          >{{ $t("Alert.doyouwanttosave") }}</v-card-title
        >
        <v-card-actions class="justify-center align-center pb-5">
          <v-btn depressed dark color="#e83e8c" class="pa-3 mr-3" @click="saveSeason()">{{
            $t("SigninGeneral.btn_save")
          }}</v-btn>
          <v-btn color="pink" class="pa-3" outlined @click="dialogConfirm = false">{{
            $t("SigninGeneral.btn_cancel")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogBack" persistent max-width="400">
      <v-card>
        <v-card-title
          class="text-center"
          style="font-size: 1rem !important; letter-spacing: normal !important"
          >คุณต้องการออกจากหน้านี้โดยไม่บันทึกหรือไม่ ?</v-card-title
        >
        <v-card-actions class="justify-center align-center pb-5">
          <v-btn
            depressed
            dark
            color="#e83e8c"
            class="pa-3 mr-3"
            @click="backtoSeasonal()"
            >ใช่</v-btn
          >
          <v-btn color="pink" class="pa-3" outlined @click="dialogBack = false">{{
            $t("SigninGeneral.btn_cancel")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import swal from "sweetalert";
import { EventBus } from "@/EventBus";

export default {
  name: "SigninSeasonalAddScreen",
  metaInfo: {
    title: "Add Seasonal",
    titleTemplate: "%s | Aigent",
  },
  beforeRouteLeave(to, from, next) {
    var changeValue = this.checkChangeData();

    if (changeValue && !this.save_success) {
      const answer = window.confirm(this.$t("Alert.needtogootherroute"));
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else next();
  },
  data: () => ({
    userToken: "",
    propertyID: "",
    rules: {
      name: [(val) => (val || "").length > 0 || "* โปรดระบุชื่อ"],
      date: [(val) => (val || "").length > 0 || "* โปรดเลือกวันที่"],
    },
    used_date: [],
    disabledDates: ["2021-11-01"],
    dates: new Date(),
    validation: false,
    season_name: "",
    date_input: [
      {
        start_menu: false,
        start_format: "",
        start: "",
        min_startdate: "",
        end_menu: false,
        end_format: "",
        end: "",
        min_enddate: "2023-03-03",
      },
    ],
    dialogConfirm: false,
    dialogBack: false,
    before_minstart: "",
    save_success: false,
    loadingData: false,
  }),
  created() {
    EventBus.$emit("loadingtillend");
    this.userToken = this.$route.params.userToken;
    this.propertyID = this.$route.params.propertyId;
    this.GetUsedSeason();
  },
  computed: {},
  watch: {
  },
  methods: {
    async GetUsedSeason() {
      this.loadingData = true;
      try {
        const res = await this.axios.get(
          process.env.VUE_APP_API +
            "/property/seasonal/used_date?property_id=" +
            this.propertyID,
          {
            headers: {
              Authorization:
                this?.userToken ||
                localStorage?.getItem("Token") ||
                this?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || this?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          this.used_date = res.data.result;
          // console.log(this.used_date);
        }
      } catch (error) {
        console.dir(error.response);
      } finally {
        EventBus.$emit("endloading");
        this.loadingData = false;
      }
    },
    allowedDates(val) {
      // console.log(val);
      var finddate = this.used_date.find((d) => val == d);
      if (finddate === undefined) return val;
    },
    formatStart(date, index) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      this.date_input[index].start_format = `${day}/${month}/${year}`;
      this.date_input[index].start_menu = false;
      // var current = new Date(date);
      // const [m, d, y] = new Date(
      //   current.getTime()
      // ).toLocaleDateString("en-CA").split("/");
      this.date_input[index].min_enddate = date;
      // console.log(this.date_input[index].min_enddate);
    },
    formatEnd(date, index) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      this.date_input[index].end_format = `${day}/${month}/${year}`;
      this.date_input[index].end_menu = false;
      var current = new Date(date);
      this.before_minstart = new Date(current.getTime() + 86400000).toLocaleDateString(
        "en-CA"
      );
    },
    checkSameday(start, end, index) {
      const dateCheck = this.getRangeDates(new Date(start), new Date(end));
      const dateConvert = [];
      dateCheck.forEach((date) => {
        var converted = this.convertDate(date);
        dateConvert.push(converted);
        return date;
      });
      this.checkDate(dateConvert, index);
    },
    getRangeDates(startDate, endDate) {
      const dates = [];
      let currentDate = startDate;
      const addDays = function (days) {
        const date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
      };
      while (currentDate <= endDate) {
        dates.push(currentDate);
        currentDate = addDays.call(currentDate, 1);
      }
      return dates;
    },
    convertDate(str) {
      var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day].join("-");
    },
    formatDateDisplay(date) {
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    checkDate(dates, index) {
      var same = dates.filter((d) => this.used_date.includes(d));
      // console.log(same);
      if (same.length > 0) {
        var date1 = this.formatDateDisplay(same[1]);
        var date2 = this.formatDateDisplay(same[same.length - 1]);
        var text = "ไม่สามารถเลือกวันที่ระหว่าง " + date1 + " ถึง " + date2 + " ได้";
        swal(this.$t("Alert.warn_title"), this.$t(text), this.$t("Alert.warn_label"));
        this.date_input[index].end_format = "";
        this.date_input[index].end = "";
      }
    },
    AddRangedate() {
      this.date_input.push({
        start_menu: false,
        start_format: "",
        start: "",
        min_startdate: this.before_minstart,
        end_menu: false,
        end_format: "",
        end: "",
        min_enddate: "",
      });
      setTimeout(() => {
        this.before_minstart = "";
      }, 1000);
    },
    RemoveRangedate(index) {
      // console.log("remove =", index);
      this.date_input.splice(index, 1);
      var current = new Date(this.date_input[index - 1].end);
      // console.log(current)
      this.before_minstart = new Date(current.getTime() + 86400000).toLocaleDateString(
        "en-CA"
      );
    },
    async saveSeason() {
      // EventBus.$emit("loadingtillend");
      var dataObj = {
        seasonal_name: this.season_name,
        date_slot: this.date_input,
        expired_date: this.date_input[0].end,
      };
      // console.log("dataObj =", dataObj);
      try {
        const res = await this.axios.post(
          process.env.VUE_APP_API + "/property/seasonal?property_id=" + this.propertyID,
          dataObj,
          {
            headers: {
              Authorization:
                this?.userToken ||
                localStorage?.getItem("Token") ||
                this?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || this?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          this.save_success = true;
          swal(
            this.$t("Alert.success_title"),
            this.$t("Alert.save_success"),
            this.$t("Alert.success_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal("Warning", error?.response?.data?.message || "Please try again", "warning", {
          button: false,
          timer: 3000,
        });
      } finally {
        EventBus.$emit("endloading");
        const item = {
          name: "SigninSeasonalScreen",
          params: {
            propertyId: self.propertyID,
            userToken: self.userToken,
          },
        };
        setTimeout(() => {
          EventBus.$emit("changePathname", item);
          EventBus.$emit("endloading");
        }, 1500);
      }
    },
    ConfirmBack() {
      // if (
      //   this.season_name !== "" ||
      //   this.date_input[0].start !== "" ||
      //   this.date_input[0].end !== ""
      // ) {
      //   this.dialogBack = true;
      // } else {
      //   this.backtoSeasonal();
      // }
      this.backtoSeasonal();
    },
    backtoSeasonal() {
      this.dialogBack = false;
      EventBus.$emit("loadingtillend");
      const item = {
        name: "SigninSeasonalScreen",
        params: {
          propertyId: self.propertyID,
          userToken: self.userToken,
        },
      };
      setTimeout(() => {
        EventBus.$emit("changePathname", item);
        EventBus.$emit("endloading");
      }, 1500);
    },
    checkChangeData() {
      const self = this;
      var cantleavethispage = false;
      if (self.season_name || self.date_input?.length > 2) cantleavethispage = true;
      else cantleavethispage = false;
      return cantleavethispage;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../styles/bootstrap/global.css";
@import "../../../../styles/signin/setting.css";
</style>

<style scoped>
.inputtext {
  display: block;
  height: 40px;
  background-color: white;
}

swal-footer {
  text-align: center;
}
</style>
